.react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    font-family: "lato";
    font-weight: 400;
}

.selected-month {
    background-color: black !important;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
}

.react-datepicker__month-text--today {
    font-family: "lato";
    font-weight: 400;
}

.react-datepicker__month-text {
    padding: 5px;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 83px !important;
}

.react-datepicker__month-text:hover {
    background-color: #30384A !important;
}

.react-datepicker {
    border: none;
    padding: 0;
}


.react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    font-family: "lato";
    margin-top: 5px;
    font-weight: 700;
}

.react-datepicker__day {
    height: 25px;
    width: 83px;
}

.react-datepicker__current-month,
.react-datepicker__day-names {
    color: #333;
}

.react-datepicker__current-month {
    margin: 0;
    padding: 0;
}

.react-datepicker__month-text--selected {
    background-color: #30384A;
    color: white;
}

.react-datepicker__month-text--in-range {
    background-color: #30384A;
    color: white;
}

.react-datepicker__month-text--today-selected {
    background-color: #30384A;
    color: white;
    font-family: "lato";
    font-weight: 400;
    font-size: 16px;
}

.react-datepicker__current-month {
    text-align: center;
    margin: 0;
    padding: 10px 0;
    font-size: 1.2rem;
}

.react-datepicker__month {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
}

.react-datepicker__month-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 83px;
}

.react-datepicker__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 70px;
    border-color: black !important;
    border-width: 2px !important;
    font-weight: 400;
}

.react-datepicker__navigation-icon::before {
    border-color: black; /* Black arrows */
    border-style: solid;
    border-width: 2px 2px 0 0; /* Thinner arrows */
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}

.react-datepicker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    font-size: 16px;
}

.react-datepicker__month-text:hover {
    background-color: #30384A;
    color: white;
}